.h-underline{
    
    &:after{
        margin-top: ($spacer * .5) !important;
        content: '';
        display: block;
        height: 4px;
        width: 30%;
        max-width: 6rem;
        min-width: 1rem;
        margin: auto;
        background-color: $primary;
    }
}

.h-underline-left{
    @extend .h-underline;
    &:after{
        margin-left: 0;
    }
}

.h-underline-all {
    @extend .h-underline;
    &:after{
        width: 100%;
        max-width: 100%;
        margin-left: 0;
    }
}