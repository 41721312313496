/*define styles here*/
@import "node_modules/bootstrap/scss/functions";
$body-bg: #0b1228;
$body-color: #fffeff;
$close-color: $body-color;
$light: rgba(white, 0.07);
$super-light: rgba(white, 0.02);
$orange: #f8ae0a;
$secondary: #fffeff;
$panel: #172245;
$theme-colors: (
  "primary": blue,
  "orange": $orange,
  "secondary": $secondary,
  "super-light": $super-light,
  "panel": $panel,
);
$input-bg: #0b1228;
$input-color: #fffeff;
$container-max-widths: (
  sm: 960px,
  md: 960px,
  lg: 960px,
  xl: 1680px,
) !default;
$input-disabled-bg: #454646;
$modal-content-bg: #0b1228;
$modal-backdrop-bg: #fff;
$modal-backdrop-opacity: 0.2;
$modal-header-border-color: rgba(white, 0.1);
$modal-footer-border-color: rgba(white, 0.1);
$border-color: rgba(white, 0.1);
// bootstrap
$link-color: rgba(#fffeff, 0.8);
$link-hover-color: #fffeff;
$font-family-sans-serif: "Poppins", sans-serif;
$enable-rounded: false;

$font-size-base: 0.875rem;

// @import "./bootstrap-variables";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// custom
@import "node_modules/bootstrap/scss/bootstrap";

.nav-link.active {
  color: white;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.open > .dropdown-toggle.btn-secondary {
  color: #fff;
  background-color: $orange;
  border-color: $orange; /*set the color you want here*/
}

// header
nav.static-top {
  background-size: cover;
  background-position: center center;
}

.header-progress {
  &__link {
    text-decoration: none !important;
  }
}

.signal-type {
  width: 100%;
  &.recovered {
    background: #008888 !important;
  }
  &.sick {
    background: #324f7d !important;
  }
  &.risk {
    background: orange !important;
  }
}

.signal-status {
  width: 100%;
  background: gray !important;
  &.passed_deadline {
    background: orange !important;
  }
  &.new {
    background: #324f7d !important;
  }
  &.update {
    background: #008888 !important;
  }
  &.closed {
    background: green !important;
  }
  &.contact_teacher {
    background: magenta !important;
  }
  &.intervention {
    background: red !important;
  }
}

.signal-date {
  background: $light;
  color: $body-color;
}

.btn-radio {
  &.btn-outline-success,
  &.btn-outline-danger {
    color: $body-color;
    border-color: $secondary;
  }
  &.btn-outline-danger:hover {
    border-color: $danger;
  }
  &.btn-outline-success:hover {
    border-color: $success;
  }
}
.signals {
  min-height: 70vh;
}
.signals {
  &__item {
    &.-odd {
      background-color: rgba(255, 255, 255, 0.08);
    }
    &:hover {
      background-color: rgba($panel, 0.7);
    }
    &.-active {
      background-color: $panel;
    }
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

body {
 overflow-x: hidden;
}

.toggle {
  overflow: hidden;
  opacity: 0;
  max-height: 0px;
  // -webkit-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;

  &.-open {
    opacity: 1;
    max-height: 9999px;
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.glyphicon {color: #ff9900 !important;}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.rdp-input-group{

  .rdp-popover{
    .popover{
      background: #172245;
    }
    .popover-header{
      background: #162452;
    }
  }
  .rdp-addon{
    border: 1px solid #ced4da;
    .input-group-text {
      border: none;
      background-color: transparent;
    }
  }
  .rdp-calendar{

    tr td:nth-child(6),
    tr td:nth-child(7){
      background-color: #34495e5c;
    }
  }
}

.rounded {
  border-radius: 1em;
}

.rounded-top {
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}


td, th {
  background-color: inherit !important;
}

.header-item {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: 1.5em;
  text-decoration: underline;
  font-size: medium
}

body {
  background-image: url("../img/bg2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $body-bg
}

@import "./_typography.scss";